<template>
  <div>
    <ion-item
      style="border-radius: 10px; box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;" 
      lines="none"
      href="#"
      detail
      class="mt-2 mb-2">
      <ion-avatar style="width: 70px; height: 70px" slot="start">
        <img
          alt="Silhouette of a person's head"
          :src="'https://picsum.photos/125/125/?image=58'"
        />
      </ion-avatar>
      <ion-label
        slot="end"
        v-show="log.createdAt"
        style="margin-right: 10px; margin-top: 5px;">
        <small class="text-muted">
          <i class="bi bi-clock"></i> {{ formatDate(log.createdAt) }}
        </small>
      </ion-label>
      <ion-col>
      <div class="ion-text-wrap">
        <div class="title">
          {{ log.description }}
        </div>
      </div>
    </ion-col>
    </ion-item>
  </div>
</template>
<script>
import moment from "moment";
import { createNamespacedHelpers } from "vuex"
import { IonCol, IonItem, IonLabel, IonAvatar } from "@ionic/vue"

const ActivityModule = createNamespacedHelpers('activity')

export default {
  name: "ActivityLogItem",
  props: {
    log: {
      type: Object,
      required: true,
    },
  },
  data(){
    return {
      avatar: ''
    }
  },
  components: {
    IonCol,
    IonItem,
    IonLabel,
    IonAvatar,
  },
  methods: {
    ...ActivityModule.mapActions(['fetchUserAvatar']),

    formatDate(createdAt) {
      return moment(createdAt).format("hh:mm a");
    },
  },
  async mounted() {
    // try{
    //   const avatar = await this.fetchUserAvatar(this.log.user)
    //   this.avatar = avatar
    // }
    // catch(err){
    //   console.error("Failed to fetch user avatar")
    // }
  }

};
</script>

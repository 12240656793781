<script>
import { createNamespacedHelpers } from 'vuex'
const ActivityModule = createNamespacedHelpers('activity')

export default {
  name: 'ActivityBase',
  data(){
    return {}
  },
  computed: {
    ...ActivityModule.mapGetters(['selectLogsTimeline']),

    logsTimeline(){
      return this.selectLogsTimeline
    }
  },
  methods: {},
}
</script>
<template>
  <ion-page>
    <ion-header>
      <ion-toolbar></ion-toolbar>
    </ion-header>
    <ion-content :style="settings.bg">
      <div class="container">
        <div class="activities-page mt-4">
          <div class="header mb-5">
            <ActivityHeader @isSearching="handleIsSearching"/>
          </div>

          <div class="log-timeline" v-if="!isSearching && !isNotEmpty">
            <div v-for="(value,  timestamp) in logsTimeline" :key="timestamp">
              <ActivityTimeline :timestamp="timestamp" :value="value"/>
            </div>
          </div>

          <NoResultsFound message="No activity was found. Try changing the parameters." v-else/>

          <div v-if="isSearching" class="d-flex justify-content-center mt-5">
            <div 
              class="spinner-border text-info" role="status" 
              style="width: 3rem; height: 3rem;">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import router from "@/router";
import moment from "moment";
import ActivityBase from "../modules/activity/ActivityBase.vue";
import ActivityHeader from "../modules/activity/ActivityHeader.vue"
import NoResultsFound from '../pages/NoResultsFound.vue'
import { mapActions, createNamespacedHelpers } from "vuex";
const ActivityModule = createNamespacedHelpers('activity')

import {
  IonPage,
  IonContent,
  IonToolbar,
  IonHeader,
} from "@ionic/vue";
import lodash from "lodash"
import Toaster from '../../../lib/toaster'
import ActivityTimeline from "../modules/activity/ActivityTimeline.vue"
const SettingsModule = createNamespacedHelpers("setting");

export default {
  name: "Notification",
  extends: ActivityBase,
  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    NoResultsFound,
    ActivityHeader,
    ActivityTimeline,
  },
  data() {
    return {
      fullName: "Nemese Kalubi",
      isSearching: false,
    };
  },
  computed: {
    ...SettingsModule.mapGetters([
      "selectUserSettings",
      "selectApplicationSettings",
    ]),

    settings() {
      return this.selectUserSettings;
    },
    timestamp() {
      return moment().format("LL");
    },
    timestampTwo() {
      return moment().format("LL");
    },
    isNotEmpty(){
      return lodash.isEmpty(this.logsTimeline)
    }
  },
  methods: {
    ...ActivityModule.mapActions(['fetchActivityLogs']),
    ...mapActions(["changePageTitle", "navigateToOption"]),

    async setPageTitle() {
      await this.changePageTitle(router.currentRoute._value.name);
    },

    async loadUserActivityLogs(){
      try{
        await this.fetchActivityLogs()
      }
      catch(err){
        Toaster.error({
          message: 'Failed to load user activity'
        })
      }
    },
    handleIsSearching({ value }){
      this.isSearching = value
    }
  },
  async mounted() {
    this.setPageTitle();
    this.loadUserActivityLogs()
  },
};
</script>

<template>
  <div>
    <small class="text-muted">
        <i class="bi bi-clock"></i> {{ timestamp }}
      </small>
      <div class="mt-3 mb-3" v-for="log in value.logs" :key="log._id">
        <ActivityLogItem :log="log" />
      </div>
  </div>
</template>

<script>
import ActivityLogItem from "./card/ActivityLogItem.vue";

export default {
  name: "ActivityTimeline",
  props: {
    timestamp: {
      required: true,
    },
    value: {
      required: true,
    },
  },
  components: {
    ActivityLogItem,
  },
};
</script>

<style lang="scss" scoped></style>

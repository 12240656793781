<template>
  <div class="activity-header">
    <div class="card" style="border-radius: 5px; border: none">
      <div class="card-body py-5 px-4">
        <div
          class="row d-flex flex-wrap justify-content-between align-items-center">
          <div class="col-md-4 col-sm-12 mb-2">
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="search-icon">
                    <i class="bi bi-search"></i>
                  </span>
                </div>
                <input
                  type="text"
                  class="form-control search-input"
                  placeholder="Search activities"
                  aria-label="activities"
                  aria-describedby="search-icon"
                  v-model="searchTerm"
                  @input="searchLogs"
                />
              </div>
            </div>
          </div>

          <div class="col-md-4 col-sm-12 mb-2">
            <div class="form-group">
              <b-form-select
                class="form-control"
                v-model="selected"
                :options="categories"
              >
              </b-form-select>
            </div>
          </div>

          <div class="col-md-4 col-sm-12 mb-2">
            <div class="">
              <Datepicker
                id="fromDatepicker"
                autoApply
                range
                multiCalendars
                format="MM/dd/yyyy"
                :closeOnAutoApply="true"
                v-model="dateRange"
                @closed="searchLogs"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { debounce } from "lodash"
import Datepicker from "@vuepic/vue-datepicker"
import { createNamespacedHelpers } from "vuex"

const ActivityModule = createNamespacedHelpers('activity')

export default {
  name: "ActivityHeader",
  components: { Datepicker },
  data() {
    return {
      selected: null,
      categories: [
        { value: null, text: "All categories" },
        { value: "a", text: "This is First option" },
        { value: "b", text: "Selected Option" },
        { value: { C: "3PO" }, text: "This is an option with object value" },
        { value: "d", text: "This one is disabled", disabled: true },
      ],
      dateRange: [moment().subtract(30, "days"), moment()],
      searchTerm:  null,
    };
  },
  methods: {
    ...ActivityModule.mapActions(['searchActivityLogs']),
    searchLogs: debounce(async function(event) {
      this.$emit('isSearching', { value: true })
      const query = {
        search: this.searchTerm,
        dateRange: this.dateRange
      }
      await this.searchActivityLogs(query)
      this.$emit('isSearching', { value: false })
    }, 500)
  },
};
</script>

<style lang="scss" scoped>
.search-input {
  outline: none;
  border: none !important;
}
.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}
#search-icon {
  border: none !important;
  background-color: white !important;
}
</style>
